import Icon from 'rv-components-onze/src/Icon'
import { generalTerms, socialMediaLinks } from './constants'

const Footer = () => {
  return (
    <div className="footer-landing">
      <div className="container footer-landing__content">
        <div className="footer-landing__main-info">
          <div className="footer-landing-main-info__onze">
            <h4 className="footer-landing-main-info__title">Termos legais</h4>

            {generalTerms.map((item, index) => (
              <a className="footer-landing-main-info__description" href={item.link} title={item.name} target="_blank" key={`${item.name}-${index}`}>
                {item.text}
              </a>
            ))}
          </div>

          <div className="footer-landing-main-info__onze">
            <h4 className="footer-landing-main-info__title">Links úteis</h4>
            <a className="footer-landing-main-info__description" href="https://www.onze.com.br/blog/calculadora-da-previdencia/" target="_blank" title="Calculadora da aposentadoria">
              Calculadora da Aposentadoria
            </a>
            <a className="footer-landing-main-info__description" href="https://www.onze.com.br/blog/" target="_blank" title="Blog da Onze">
              Blog
            </a>
          </div>

          <div className="footer-landing-main-info__onze">
            <div className="address">
              <h4 className="footer-landing-main-info__title">Fale conosco</h4>
              <p className="footer-landing-main-info__description">
                rcovre@onze.com.br
              </p>
              <p className="footer-landing-main-info__description">
                (11) 2222-1251
              </p>
              <p className="footer-landing-main-info__description">
                Horário de atendimento:
                dias úteis, de 9h às 19h
              </p>

              <div className="social-links">
                <h4 className="social-links__title">Siga-nos</h4>

                {socialMediaLinks.map((item, index) => (
                  <a className="social-links__item" target="_blank" title={`${item.icon} da Onze`} href={item.link} key={`${item.icon}-${index}`}>
                    <Icon iconId={`icon-${item.icon}`} />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-landing__rights rights">
        <div className="container footer-landing__content">
          <Icon iconId="onze-logo" />
          <div className="rights__text">
            <p>© Onze Gestora de Investimentos Ltda. CNPJ: 34.008.311/0001-19 - Todos os direitos reservados</p>
            <p>Rua Fidêncio Ramos, 242, 4º andar - Itaim Bibi, São Paulo</p>
          </div>
        </div>
      </div>

      <div className="footer-landing__certifications">
        <div className="container">
          <div>
            <h4 className="footer-landing__certifications-title">Certificações</h4>
            <img className="footer-landing__certifications-image" src="/images/selo-anbima.png" alt="anbima" />
          </div>
          <p className="footer-landing__certifications-description">A comercialização dos produtos é realizada pela Onze corretora de seguros LTDA.</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
