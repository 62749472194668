import { useEffect } from 'react'

const useOneTrust = () => {
    useEffect(() => {
      const script = document.createElement('script')

      script.src = 'https://appds8093.blob.core.windows.net/privacy-notice-scripts/otnotice-1.0.min.js'
      script.type = 'text/javascript'
      script.charset = 'UTF-8'
      script.id = 'otprivacy-notice-script'
      
      script.onload = () => {
        OneTrust.NoticeApi.Initialized.then(function() {
          OneTrust.NoticeApi.LoadNotices(
            [
              'https://appds8093.blob.core.windows.net/79ba7c84-ebc2-4740-8d11-bf1cc4501e59/privacy-notices/c25d6d6b-4332-42fa-956c-e6c0a6d15982.json',
            ],
            false,
          )
        })
      }
      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }, [])
}

export default useOneTrust