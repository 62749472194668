export const generalTerms = [
  {
    link: 'https://www.onze.com.br/politica-de-privacidade',
    text: 'Política de Privacidade',
  },
  {
    link:
      'https://onze.com.br/blog/wp-content/uploads/2019/08/politica-de-rateio-e-divisao-de-ordens.pdf',
    text: 'Política de Rateio e Divisao de Ordens',
  },
  {
    link:
      'https://onze.com.br/blog/wp-content/uploads/2019/08/politica-de-investimentos-pessoais.pdf',
    text: 'Política de Investimentos Pessoais',
  },
  {
    link:
      'https://onze.com.br/blog/wp-content/uploads/2019/08/politica-de-gestao-de-risco.pdf',
    text: 'Política de Gestao de Risco',
  },
  {
    link:
      'https://onze.com.br/blog/wp-content/uploads/2019/08/manual-de-compliance.pdf',
    text: 'Manual de Compliance',
  },
  {
    link:
      'https://onze.com.br/blog/wp-content/uploads/2019/08/formulario-de-referencia.pdf',
    text: 'Formulário de Referência',
  },
  {
    link:
      'https://onze.com.br/blog/wp-content/uploads/2019/08/codigo-de-etica.pdf',
    text: 'Código de Etica',
  },
]

export const socialMediaLinks = [
  {
    link: 'https://www.facebook.com/onzeprevidencia',
    icon: 'facebook',
  },
  {
    link: 'https://www.instagram.com/onzeofficial',
    icon: 'instagram',
  },
  {
    link: 'https://www.linkedin.com/company/onze-oficial',
    icon: 'linkedin',
  },
]
