import enhancer from './hooks'
import Icon from 'rv-components-onze/src/Icon'
import menuList from 'constants/menuList'
import { Link } from 'gatsby'
import { BASE_URL } from 'gatsby-env-variables'

const Menu = ({ menuIsOpened, onClickMenu, goTo, onClickSidebar }) => {
  return (
    <div
      className={`menu-landing ${menuIsOpened ? 'menu-landing--active' : ''}`}>
      <div className="container menu-landing__content">
        <Icon onClick={onClickMenu} iconId="close" />
        <div className="menu-landing__list">
          <ul>
            {menuList.map((item, idx) => (
              <li key={`${item.name}-${idx}`} className="menu-landing__item">
                {
                  item.path ?
                    <Link
                      to={item.path}
                      title={item.title}
                      className="menu-landing__item-link"
                      onClick={onClickMenu}
                    >
                      {item.name}
                    </Link>
                    :
                    <a
                      href={item.href}
                      target="_blank"
                      className="menu-landing__item-link"
                    >
                      {item.name}
                    </a>
                }
              </li>
            ))}
          </ul>
        </div>

        <div className="menu-landing__buttons-container">
          <a href={`${BASE_URL}painel/login/`} title="Ir para tela de login" className="menu-landing__button">Login</a>

          <button className="menu-landing__button menu-landing__button--reverse" onClick={onClickSidebar}>
            Quero contratar
          </button>
        </div>
      </div>
    </div>
  )
}

export default enhancer(Menu)
