import Header from 'components/Header'
import Menu from 'components/Menu'
import Footer from 'components/Footer'

//import PoliceContent from './components/PoliceContent'
import useOneTrust from './hooks/useOneTrust'

const PrivacyPolice = () => {
	useOneTrust()

  return (
    <div>
      <Header />
      <Menu />
      {/* <PoliceContent /> */} 
      <div
        id="otnotice-c25d6d6b-4332-42fa-956c-e6c0a6d15982"
        className="otnotice privacy-police container"
      />
      <Footer />
    </div>
  )
}

export default PrivacyPolice
